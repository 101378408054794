<template>
  <div>
    <ComHeader menuKey="index"></ComHeader>
    <!-- banner -->
    <!-- <div class="banner">
      <div class="container">
        <img
          class="banner-txt animate__animated animate__headShake"
          src="@/assets/images/home-banner-tex.png"
          alt=""
        />
        <div class="discrip animate__animated animate__backInRight">
          <span>活体人像实时抓拍</span>
          <span>CTID网证身份极速核验</span>
          <span>跨域大数据风控管理</span>
          <span>覆盖全业务场景及应用</span>
        </div>
        <div class="line animate__animated animate__backInRight"></div>
      </div>
    </div> -->
    <a-carousel class="new-banner" dotsClass="dots-class" autoplay>
      <div class="new-banner-item"><img src="@/assets/images/home-banner4.png" alt=""></div>
      <div class="new-banner-item"><img src="@/assets/images/home-banner1.jpg" alt=""></div>
      <div class="new-banner-item"><img src="@/assets/images/home-banner2.jpg" alt=""></div>
      <div class="new-banner-item"><img src="@/assets/images/home-banner3.jpg" alt=""></div>
    </a-carousel>

    <div class="home-bg">
      <div class="container">
        <div class="pro-wrap">
          <router-link to="/product" class="pro-item hvr-bob">
            <div class="title">API产品服务</div>
            <p>四大标准化接口模式，安全可信直连权威数据源</p>
            <p>适用于多业务模式，满足多态化应用场景</p>
          </router-link>
          <router-link to="/authpro" class="pro-item hvr-bob">
            <div class="title">实我核验产品</div>
            <p>国家“互联网+”可信身份认证平台</p>
            <p>智慧城市的“神经末梢”</p>
            <p>您身边的核验专家</p>
          </router-link>
        </div>

        <div class="plan-wrap">
          <router-link
            to="/plandetail?id=epidemic"
            class="plan-item hvr-float-shadow"
          >
            <div class="content">
              <div class="title">公共安全</div>
              <p>PUBLIC SAFETY</p>
              <span class="to-detail">了解详情</span>
            </div>
          </router-link>
          <router-link to="/plandetail?id=community" class="plan-item hvr-float-shadow">
            <div class="content">
              <div class="title">智慧社区</div>
              <p>SMART COMMUNITY</p>
              <span class="to-detail">了解详情</span>
            </div>
          </router-link>
          <router-link
            to="/plandetail?id=hotel"
            class="plan-item hvr-float-shadow"
          >
            <div class="content">
              <div class="title">智慧酒店</div>
              <p>SMART HOTEL</p>
              <span class="to-detail">了解详情</span>
            </div>
          </router-link>
          <router-link
            to="/plandetail?id=building"
            class="plan-item hvr-float-shadow"
          >
            <div class="content">
              <div class="title">智慧楼宇</div>
              <p>SMART BUILDING</p>
              <span class="to-detail">了解详情</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="tip-wrap">
      <div class="container ui acenter">
        <img src="@/assets/images/home-tip-img.png" alt="" />
        <span class="tip-txt">中码科技，您身边的核验专家</span>
        <img class="tip-img" src="@/assets/images/home-tip-img.png" alt="" />
      </div>
    </div>

    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    return {};
  },
});
</script>

<style scoped lang="less">
.banner {
  background: url("../../assets/images/home-banner.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 27px;
  padding: 200px 0;
  .banner-txt {
    width: 600px;
    margin-bottom: 55px;
  }
  .line {
    width: 50px;
    height: 4px;
    background-color: #fff;
    margin-top: 80px;
  }
  .discrip {
    display: flex;
    width: 1000px;
    justify-content: space-between;
    font-size: 20px;
    font-weight: normal;
  }
}
.new-banner{
  .new-banner-item{
    img{
      width: 100%;
      max-width: 2000px;
      margin: 0 auto;
    }
  }
}
/deep/.dots-class{
  li{
    button{
      
      width: 30px;
      height: 6px;
      background: #4682fb;
      &:hover{
        opacity: 1;
      }
    }
    &.slick-active button{
      background: rgba(0, 6, 53, 1);
      width: 30px;
    }
  }
}
.home-bg {
  background: url("../../assets/images/home-bg.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 160px;
}
.pro-wrap {
  display: flex;
  justify-content: space-between;
  .pro-item {
    width: 585px;
    height: 355px;
    padding: 30px;
    color: #fff;
    margin: 80px 0;
    p {
      margin-bottom: 2px;
    }
    &:nth-child(1) {
      background: url("../../assets/images/home-api.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(2) {
      background: url("../../assets/images/home-sw.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
}
.plan-wrap {
  display: flex;
  justify-content: space-between;
  .plan-item {
    width: 276px;
    height: 421px;
    padding: 20px 23px;
    color: #fff;
    font-size: 19px;
    .content {
      margin-top: 255px;
      .to-detail {
        display: block;
        width: 124px;
        height: 36px;
        color: #0e0753;
        background: url("../../assets/images/more-btn.png") no-repeat;
        background-size: 100% 100%;
        padding: 5px 8px;
        font-size: 16px;
      }
    }
    .title {
      font-size: 23px;
      margin-bottom: 5px;
    }
    &:nth-child(1) {
      background: url("../../assets/images/home-safe.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(2) {
      background: url("../../assets/images/home-park.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(3) {
      background: url("../../assets/images/home-hotal.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(4) {
      background: url("../../assets/images/home-budding.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.tip-wrap {
  background-color: #f9f9fd;
  padding: 80px 0 30px;
  text-align: center;
  .tip-txt {
    font-size: 30px;
    color: #142d70;
    margin: 0 20px;
  }
  img {
    width: 380px;
    height: 5px;
  }
  .tip-img {
    transform: rotate(180deg);
  }
}
</style>

<style lang="less" scoped>
.mobile {
  .home-bg{
    padding-bottom: 50px;
  }
  .banner{
    padding: 100px 20px 50px;
    .banner-txt{
      width: 100%;
      margin-bottom: 30px;
    }
    .discrip{
      display: block;
      width: 100%;
      font-size: 15px;
      span{
        display: block;
      }
    }
    .line{
      margin-top: 30px;
    }
  }
  .new-banner{
    padding-top: 60px;

  }
  .pro-wrap{
    display: block;
    padding: 0 20px;
    .pro-item{
      width: 100%;
      height: 260px;
      margin: 50px 0 0 0;
      font-size: 14px;
      .title{
        font-size: 22px;
      }
    }
  }
  .plan-wrap{
    flex-wrap: wrap;
    margin: 50px 20px;
    .plan-item{
      width: 49%;
      height: 320px;
      margin-bottom: 2%;
      font-size: 11px;
      .content{
        margin-top: 150px;
      }
    }
  }
  .tip-wrap{
    padding: 40px 0 0;
    img{
      width: 20%;
    }
    .tip-txt{
      font-size: 16px;
      margin: 0 auto;
      font-weight: 600;
    }
  }
}
</style>